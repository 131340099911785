<template>

<app-wrapper class="foot">

	<app-wrapper :content="true" class="foot-content">

		<div class="foot-contact">

			<div v-if="$state.footer.phone" class="foot-contact-item has-text"><a :href="'tel:' + $state.footer.phone"><i class="fa fa-mobile"></i> {{ $state.footer.phone}}</a></div>
			<div v-if="$state.footer.twitter" class="foot-contact-item"><a :href="$state.footer.twitter" target="_blank"><i class="fa fa-twitter"></i></a></div>
			<div v-if="$state.footer.linkedin" class="foot-contact-item"><a :href="$state.footer.linkedin" target="_blank"><i class="fa fa-linkedin"></i></a></div>
			<div v-if="$state.footer.youtube" class="foot-contact-item"><a :href="$state.footer.youtube" target="_blank"><i class="fa fa-youtube"></i></a></div>

		</div>

		<div class="foot-legal">

			<ul class="foot-legal-links">
				<li v-for="(link, index) in $state.footer.links" :key="index"><router-link :to="{path: link.url}">{{ link.name }}</router-link></li>
			</ul>

			<p class="foot-legal-text" v-html="$state.footer.legal" />

		</div>

		<div class="foot-logo">

			<!-- Start of SRA Digital Badge code -->
			<div style="max-width:275px;max-height:163px;"><div style="position: relative;padding-bottom: 59.1%;height: auto;overflow: hidden;"><iframe frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" style="border:0px; margin:0px; padding:0px; backgroundColor:transparent; top:0px; left:0px; width:100%; height:100%; position: absolute;"></iframe></div></div>
			<!-- End of SRA Digital Badge code -->

		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.foot {
	background-color: #112A45;
	padding: 64px 0px;
	z-index: 2;
}

.foot-content {
	display: flex;
}

.is-tablet .foot-content,
.is-mobile .foot-content {
	flex-direction: column;
}

.foot-contact {
	width: 267px;
	flex-shrink: 0;
	background-image: url(~@/assets/logo.png);
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-size: 268px auto;
	display: flex;
	align-items: center;
}

.is-tablet .foot-contact,
.is-mobile .foot-contact {
	background-position: 50% 0px;
	background-size: 200px auto;
	width: 100%;
	padding-top:50px;
	justify-content: center;
}

.foot-contact-item {
	padding-right: 15px;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
}

.is-desktop .foot-contact-item:hover i {
	color: #fff;
}

.foot-contact-item i {
	color: #BBD9CD;
	font-size: 16px;
}

.foot-contact-item.has-text {
	padding-right: 20px;
	line-height: 20px;
}

.foot-contact-item.has-text i {
	margin-right: 5px;
}

.foot-legal {
	flex-grow: 1;
	padding: 32px 30px 0px 40px;
}

.is-tablet .foot-legal,
.is-mobile .foot-legal {
	padding: 32px 0px;
}

.foot-legal-links {
	display: flex;
	margin-bottom: 40px;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.is-tablet .foot-legal-links {
	justify-content: center;
}

.is-mobile .foot-legal-links {
	flex-direction: column;
}

.foot-legal-links li {
	text-align: center;
	padding: 4px 0px;
	margin-right: 10px;
}

.is-mobile .foot-legal-links li {
	border-right: 0px;
	padding: 5px 0px;
}

.foot-legal-links li:first-child {
	padding-left: 0px;
}

.foot-legal-links li:last-child {
	padding-right: 0px;
	border-right: 0px;
}

.foot-legal-links a {
	font-size: 12px;
	letter-spacing: 1.2px;
	font-weight: 500;
	color: #fff;
}

.foot-legal-links a:hover {
	text-decoration: underline;
}

.foot-legal-text {
	color: #fff;
	font-size: 11px;
	line-height: 15px;
	letter-spacing: 1.1px;
	font-weight: 400;
}

.is-tablet .foot-legal-text,
.is-mobile .foot-legal-text {
	text-align: center;
}

.foot-logo {
	width: 275px;
	flex-shrink: 0;
}

.is-tablet .foot-logo,
.is-mobile .foot-logo {
	width: 100%;
	text-align: center;
}

.is-tablet .foot-logo > div,
.is-mobile .foot-logo > div {
	margin: 0px auto;
}

</style>
